<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a"
            >视频录制统计</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start;">
          <div class="searchbox">
            <div
              title="学员搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">学员搜索:</span>
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div
              title="班级搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">班级搜索:</span>
              <el-select
                v-model="projectId"
                placeholder="请选择"
                remote
                size="small"
                clearable
                :remote-method="superPorjectSelect"
                @visible-change="clearProjectSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      "
                      >班级编号</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      "
                      >班级名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaProjectCode"
                      v-on:input="superPorjectSelect"
                      type="text"
                      size="small"
                      placeholder="班级编号"
                      clearable
                      style="width: 150px"
                    />
                    <el-input
                      v-model="seaProjectName"
                      v-on:input="superPorjectSelect"
                      type="text"
                      size="small"
                      placeholder="请输入班级名称"
                      clearable
                      style="margin-left: 50px; width: 250px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in projectList"
                  :key="index"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span
                    style="width: 250px; margin-left: 50px; font-size: 13px"
                    >{{ item.projectName }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top:10px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="课件名称" class="searchboxItem ci-full">
              <span class="itemLabel">课件名称:</span>
              <el-input
                v-model="kpointName"
                type="text"
                size="small"
                placeholder="请输入课件名称"
                clearable
              />
            </div>
            <div class="df" style="margin-left:10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                width="80"
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
              />
              <el-table-column
                label="手机号码"
                align="left"
                show-overflow-tooltip
                prop="mobile"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
              />
              <el-table-column
                label="课件名称"
                align="left"
                show-overflow-tooltip
                prop="kpointName"
              />
              <el-table-column
                label="操作"
                align="center"
                width="150"
              >
                <div slot-scope="scope" class="df">
                  <el-button
                    style="padding:0 5px"
                    type="text"
                    size="mini"
                    v-for="(item, index) in scope.row.videoIds"
                    :key="index"
                    @click="open(item)"
                    >视频{{ index + 1 }}</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog top="2%" :visible.sync="dialogVisibles" title="视频录制查看">
      <div>
        <video
          :src="videoSrc"
          autoplay
          controls
          width="100%"
          height="500px"
        ></video>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
export default {
  name: "operate/RollCallCertificationStatisticsYw",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      userName: "",
      courseName:'',
      kpointName:'',
      compId:'',
      userId: "",
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      projectId: "",
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
      dialogVisibles: false,
      companyList:[]
    };
  },
  created() {},
  watch: {
    projectId: function() {
      this.superUserSelect();
    },
    userId: function() {
      this.superPorjectSelect();
    },
  },
  computed: {},
  mounted() {},
  methods: {
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    superPorjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
          userId: this.userId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    clearProjectSearchModel(e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superPorjectSelect();
      }
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    // 获取 - 所属机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    init() {
      this.getTableHeight();
      this.getData(-1);
    },

    //跳转
    open(item) {
      this.dialogVisibles = true;
      this.videoSrc = item;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.kpointName) {
        params.kpointName = this.kpointName;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.userId) {
        params.userId = this.userId;
      }
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
   
      this.doFetch({
        url: "/biz/user/video/pageList",
        params,
        pageNum,
      });
    },
    /* 获取高度 */
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 60;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
.operationControl {
  .searchboxItem {
    margin-right: 0;
  }
}
</style>
<style lang="less" scope>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
